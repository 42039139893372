import { ThemeProvider as NextThemeProvider } from 'next-themes';

const nextThemes = ['radio javan', 'dark-plus'];

const GlobalProviders = ({ children }) => {
  return (
    <NextThemeProvider themes={nextThemes} enableSystem={false} defaultTheme="dark-plus">
      {children}
    </NextThemeProvider>
  );
};

export default GlobalProviders;
