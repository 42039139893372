import ThemeProvider from '@contexts/ThemeProvider';
import SWR from '@contexts/SWR';
import { AppProvider } from '@contexts/AppContext';

const Global = ({ children, data, platform, requireAuthentication }) => {
  return (
    <SWR>
      <ThemeProvider>
        <AppProvider data={data} platform={platform} requireAuthentication={requireAuthentication}>
          {children}
        </AppProvider>
      </ThemeProvider>
    </SWR>
  );
};

export default Global;
