import { SWRConfig } from 'swr';

const config = {
  dedupingInterval: 3 * 60 * 100,
  focusThrottleInterval: 10_000,
  loadingTimeout: 1_200
};

const SWR = ({ children }) => {
  return <SWRConfig value={config}>{children}</SWRConfig>;
};

export default SWR;
